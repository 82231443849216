import React from "react";
import "../styles/Services.css";
import InsightsIcon from '@mui/icons-material/Insights';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';

function Services() {
    return (
        <div className="services-main-container">

            <h1 className="services-main-header" style={{marginTop: "15px"}}>
                Work With Us!
            </h1>

            <div className="services-box-container">

                {/* -------Bookkeeping & Accounting Advisory Services-------------*/}
                <div className="services-box">

                    <h2 style={{textAlign: "center"}}>
                        Bookkeeping &amp; Accounting Advisory Services
                    </h2>
                    <div style={{textAlign: "center", marginTop:"20px"}}>
                        <InsightsIcon style={{fontSize: 50}}/>
                    </div>
                    <div className="services-detail-container">
                        <div className="services-paragraph">
                            We understand that the requirement of the bookkeeping varies from one
                            business to another and based on your requirements and the size of
                            your business, we have introduced various packages for you to choose
                            what suits you the most. Nevertheless, we are flexible to offer you
                            customized packages as well.
                        </div>
                        <div className="services-paragraph">
                            We are well armored with the industry knowledge about the accounting
                            standards and the most recent updates, and we strongly believe that our
                            proposed one to one discussion about the management report and its
                            contents will add more value to the business in order to shape up the
                            direction of your strategic moves as we always want to grow your
                            business.
                        </div>
                        <div className="services-paragraph">
                            In a nutshell, the Management report will cover the below aspects on a
                            monthly basis:
                            <br/>
                            ✨ Statement of Financial Performance compared to previous months
                            <br/>
                            ✨ Statement of Financial Position compared to previous months
                            <br/>
                            ✨ Cash Summary
                            <br/>
                            ✨ Aged Payable and Aged Receivables Summary
                            <br/>
                            ✨ Financial Ratios along with Commentaries
                        </div>
                    </div>

                </div>

                {/* -------Single Touch Payroll-------------*/}
                <div className="services-box">

                    <h2 style={{textAlign: "center"}}>
                        Single Touch Payroll Services
                    </h2>

                    <div style={{textAlign: "center", marginTop: "20px"}}>
                        <MonetizationOnOutlinedIcon style={{fontSize: 50}}/>
                    </div>

                    <div className="services-detail-container">
                        <div className="services-paragraph">
                            With the ever-increasing workload of the payroll function of the business
                            and due to the lack of knowledge in this area has significantly increased
                            the time consumption for the Payroll function and that has caused most
                            of the future proof businesses to look out for opportunities to outsource
                            this function to BAS agents to reduce the work load and focus more on
                            their core business areas.
                        </div>
                        <div className="services-paragraph">
                            Along with the recent changes introduced by the Australian Taxation
                            Office (ATO) and due to the sensitivity of this area towards the
                            employees, it became one of the most critical areas for any small
                            businesses in order to report all the payroll information to the ATO via
                            each pay run. Therefore, we launched our services in this segment
                            separately since our Payroll services would not only include the Single

                            Touch Payroll (STP) reporting, but also that would be encompassed with
                            superannuation reporting and payments, Employees onboarding to the
                            Payroll system, Creating payroll schedules after checking the correct
                            awards, Reviewing the correct PAYG deductions along with proper
                            reconciliations and processing TFN declaration lodgments.
                        </div>
                    </div>

                </div>

            </div>

        </div>
    );
}

export default Services;