import React from "react";
import "../styles/Home.css";
import {Link} from "react-router-dom";

function Home() {
    return (
        <div className="main-container ">
            {/*<div className="main-header">*/}
            {/*    <h1> SNAPLEDGER AUSTRALIA</h1>*/}
            {/*</div>*/}
            <div className="item-container">
                <div className="img-box">
                    <img src="/book-keep/snapledger.gif" alt="Snapledger"/>
                </div>
            </div>

            <div className="item-container">
                <div className="detail-container">
                    <div><h1 className="header1">We Grow With You!</h1></div>

                    <div className="paragraph text-center">
                        <strong># 01</strong> Virtual <strong> Bookkeepers </strong> and <strong>BAS Agents </strong> in australia for small
                        businesses and professionals.
                    </div>
                    <div className="paragraph text-center">
                        We cater our services to anywhere in australia due to our virtual
                        presence at an affordable price
                    </div>
                </div>

                {/*<img className="image-container" src="/book-keep/snap.png" alt="Snapledger"/>*/}
            </div>

            <div className="item-container">

                <img
                    className="img-box"
                    src="/book-keep/fixed-fee.png"
                    alt="Snapledger"
                />

                <div className="detail-container">
                    {/*<h1 className="header1">Fixed Fee Bookkeeping Services</h1>*/}
                    <div className="paragraph">
                        Since the majority of our clients are small businesses, managing
                        cashflow in an effective and efficient way is paramount to the
                        success of any small business. Therefore, we provide our services at
                        a fixed fee with numerous packages for you to choose as per your
                        requirements
                    </div>
                    <div className="paragraph">
                        Hence, you will see no more surprise bills from your bookkeeper!
                    </div>
                </div>

            </div>

            <div className="service-main-container">
                <div className="paragraph text-center">
                    At SNAPLEDGER, our mission is to provide timely and affordable
                    services to support your business with your finance books while you
                    focus on your core segments of your business
                </div>

                <div className="service-container">
                    <Link to="/services">
                        {/*<h3>BOOKKEEPING <br/>&amp; <br/>ACCOUNTING ADVISORY SERVICES</h3>*/}
                        <img
                            className="service-background-image"
                            src="/book-keep/service1.png"
                            alt="Snapledger"
                        />
                    </Link>

                    <Link to="/services">
                        {/*<h3>SINGLE TOUCH PAYROLL</h3>*/}
                        <img
                            className="service-background-image"
                            src="/book-keep/service2.png"
                            alt="Snapledger"
                        />
                    </Link>

                    <Link to="/services">
                        {/*<h3>SINGLE TOUCH PAYROLL</h3>*/}
                        <img
                            className="service-background-image"
                            src="/book-keep/service3.png"
                            alt="Snapledger"
                        />
                    </Link>
                </div>

            </div>
        </div>
    );
}

export default Home;
