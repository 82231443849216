import React from 'react';
import "../styles/About.css";

function About() {
    return (
        <div className="main-container ">

            <div className="main-header">
                <h1> About Us</h1>
            </div>

            <div className="about-container">
                <h1 className="about-header">SNAPLEDGER AUSTRALIA</h1>
                <div className="about-detail-container">

                    <div className="about-paragraph">
                        Welcome to Snapledger, Where Numbers Tell the Story!

                        Are you ready to transform your business's financial landscape? Look no further! At Snapledger,
                        we don't just crunch numbers; we craft narratives that drive success
                    </div>
                    <div className="about-paragraph">
                        <strong>🔍 Who We Are </strong> <br/>
                        Snapledger is not your average bookkeeping firm. We're the storytellers behind your financial
                        data, translating complex figures into actionable insights. With a team of meticulous number
                        wizards and strategic thinkers, we're dedicated to empowering businesses like yours to thrive.
                    </div>
                    <div className="about-paragraph">
                        <strong>🚀 What Sets Us Apart </strong><br/>
                        ✨ Innovative Solutions: Say goodbye to outdated bookkeeping methods. We leverage cloud
                        technology and industry-leading practices to streamline your financial processes and drive
                        efficiency. <br/>
                        ✨ Personalized Approach: No two businesses are alike, and neither are their financial needs. We
                        tailor our services to fit your unique goals, providing personalized solutions that deliver
                        tangible results.<br/>
                        ✨ Proactive Guidance: We're not just here to tally up your receipts. We're your trusted
                        advisors, offering proactive guidance and strategic direction to help you navigate the
                        ever-changing financial landscape.
                    </div>
                </div>

            </div>


        </div>
    );
}

export default About;