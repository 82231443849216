import React from "react";
import "../styles/Footer.css";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import {Link} from "react-router-dom";

function Footer() {
    return (
        <div className="footer">
            <div className="socialMedia">

                <Link to="/contact">
                    <ContactMailIcon/>
                </Link>

                <a
                    href={
                        "https://www.linkedin.com/company/snapledger-australia-pty-ltd/about/"
                    }
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    <LinkedInIcon/>
                </a>
            </div>
            {/*<p>*/}
            {/*  &copy; Authorised Credit Representative (Number xxxx) of Vow Financial*/}
            {/*  (Australian Credit Licence Number xxx).*/}
            {/*</p>*/}
            {/*<p> &copy; 2024 domain-name.com.au</p>*/}
            <div className="par-diff">
                <p>
                    Snapledger Australia Pty Ltd
                </p>
            </div>
            <div className="par-diff">
                <p>
                    ABN 46 675 625 055&nbsp; | &nbsp; ACN 675 625 055
                </p>
            </div>
        </div>
    );
}

export default Footer;
