import React, {useRef, useState} from 'react';
import emailjs from '@emailjs/browser';
import "../styles/Contact.css";
import EmailIcon from '@mui/icons-material/Email';
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';

function Contact() {
    const form = useRef();

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [message, setMessage] = useState("");
    const success = document.getElementById('success');
    const danger = document.getElementById('danger');
    const sendEmail = (e) => {
        e.preventDefault();

        function displaySubmitMessage(type) {
            if (type === "success") {
                success.style.display = 'block';
            } else {
                danger.style.display = 'block';
            }
            setTimeout(() => {
                danger.style.display = 'none';
                success.style.display = 'none';
            }, 3000);
        }

        emailjs.sendForm('service_b5wf9wp', 'template_rz743cc', form.current, 'GKG8e-mPk3Qco8dxX')
            .then((result) => {
                setName("");
                setEmail("");
                setPhone("");
                setMessage("");
                displaySubmitMessage("success");
            }, (error) => {
                displaySubmitMessage("danger");
            });
    };

    return (
        <div className="main-contact-container">

            <div className="item-contact-container">
                <h1>Contact Us</h1>
            </div>


            <div className="item-contact-container">

                <div style={{marginTop: "5px"}} className="detail-contact-container">
                    <EmailIcon/><strong style={{marginLeft: "5px"}}>info@snapledgeraustralia.com.au</strong>
                </div>

                <div style={{marginTop: "5px"}} className="detail-contact-container">
                    <PhoneEnabledIcon/>
                    <strong style={{marginLeft: "5px"}}>(+61) 0452589884 </strong>
                </div>
                <p style={{marginTop: "20px"}}>
                    To start the conversation about any of our financial services, simply fill the form and send.
                </p>
                <p style={{marginTop: "20px"}}>
                    Snapledger specialist will get back to you with a clear direction within 24 hours.
                </p>
            </div>

            <div className="item-contact-container">


                {/*<p>For general enquiries or to make an appointment, please use the form below.</p>*/}

                <div style={{marginTop: "10px"}}>
                    <form ref={form} id="contact-form" onSubmit={sendEmail}>

                        {/*<label htmlFor="name"> Name </label>*/}
                        <input id="input-name" name="name" placeholder="Name*" type="text" required value={name}
                               onChange={(e) => setName(e.target.value)}/>

                        {/*<label htmlFor="email"> Email </label>*/}
                        <input id="input-email" name="email" placeholder="Email*" type="email" required value={email}
                               onChange={(e) => setEmail(e.target.value)}/>

                        {/*<label htmlFor="phone"> Phone </label>*/}
                        <input id="input-phone" name="phone" placeholder="Phone*" type="tel" required value={phone}
                               onChange={(e) => setPhone(e.target.value)}/>

                        {/*<label htmlFor="message"> Message </label>*/}
                        <textarea rows="10" placeholder="Message*" name="message" value={message}
                                  onChange={(e) => setMessage(e.target.value)} required>
                            </textarea>

                        <div className="submit-message">
                            <div className="success" id="success">
                                Your message is successfully sent!
                            </div>
                            <div className="danger" id="danger">
                                The message can't be sent. Try again later!
                            </div>
                        </div>

                        <button id="button" type="submit" value="send"> Send</button>
                    </form>
                </div>

            </div>

        </div>
    );
}

export default Contact;