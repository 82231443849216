import React from "react";
import "../styles/Packages.css";
import CheckIcon from "@mui/icons-material/Check";
import {Link} from "react-router-dom";

function Packages() {
    return (
        <div className="main-container  main-container-image">
            <h1 className="main-header" style={{marginTop: "15px"}}>
                Pricing Plans For Everyone
            </h1>

            <div className="pricing-box-container">
                {/* -------Silver-------------------------------*/}
                <div className="pricing-box pricing-box-height">
                    <h2 style={{textAlign:"center"}}>Silver</h2>
                    <p className="price">
                        <sup>$</sup>28<sub>/Week</sub>
                    </p>
                    <ul className="features-list">
                        <li className="list-div">
                            <CheckIcon/> &nbsp;
                            <div>
                                <strong>1</strong> bank/credit card account
                                <br/> (In total, Upto 50 transactions/month)
                            </div>
                        </li>
                        <li className="list-div">
                            <CheckIcon/> &nbsp;
                            <div>
                                Bank/Credit Card Reconciliation <br/>
                                <strong>Monthly</strong>
                            </div>
                        </li>
                        <li className="list-div"><CheckIcon/> &nbsp; Match Invoices to Customer Payments</li>
                        <li className="list-div">
                            <CheckIcon/> &nbsp;
                            <div>Profit & Loss Statement Review <br/>
                                <strong>Quarterly</strong></div>
                        </li>
                        <li className="list-div">
                            <CheckIcon/> &nbsp;
                            <div>Review and Balance Sheet Reconciliation <br/>
                                <strong>Quarterly</strong></div>
                        </li>
                        {/*<li className="list-div"><CheckIcon/> &nbsp;BAS Preparation and Lodgement</li>*/}
                        <li className="list-div"><CheckIcon/> &nbsp;Annual Review of Financial Statements</li>
                    </ul>
                    <div className="btn-primary">
                        <Link to="/contact"><h4 style={{color:"#ffffff"}}>SELECT</h4></Link>
                    </div>
                </div>
                {/* -------GOLD-------------------------------*/}
                <div className="pricing-box pricing-box-height">
                    <h2 style={{textAlign:"center"}}>Gold</h2>
                    <p className="price">
                        <sup>$</sup>45<sub>/Week</sub>
                    </p>
                    <ul className="features-list-special">
                        <li className="list-div">
                            <CheckIcon/> &nbsp; <div>
                            <strong>2</strong> bank/credit card account
                            <br/> (In total, Upto 90 transactions/month)</div>
                        </li>
                        <li className="list-div">
                            <CheckIcon/> &nbsp; <div>
                            Bank/Credit Card Reconciliation <br/>
                            <strong>Weekly</strong> </div>
                        </li>
                        <li className="list-div"> <CheckIcon/> &nbsp; Match Invoices to Customer Payments</li>
                        <li className="list-div">
                            <CheckIcon/> &nbsp; <div>
                            Enter Bills
                            <br/>
                            <p style={{fontStyle: "Italic"}}>
                                This includes sending remittance advises, processing of bank
                                file for bulk payments
                            </p> </div>
                        </li>
                        <li className="list-div"> <CheckIcon/> &nbsp; Month End Journal Entries</li>
                        <li className="list-div">
                            <CheckIcon/> &nbsp; <div> Profit & Loss Statement Review <br/>
                            <strong>Monthly</strong> </div>
                        </li>
                        <li className="list-div">
                            <CheckIcon/> &nbsp; <div>Review and Balance Sheet Reconciliation <br/>
                            <strong>Quarterly</strong> </div>
                        </li>
                        {/*<li className="list-div"> <CheckIcon/> &nbsp; BAS Preparation and Lodgement</li>*/}
                        <li className="list-div"> <CheckIcon/> &nbsp; Annual Review of Financial Statements</li>
                    </ul>

                    <div className="btn-primary">
                        <Link to="/contact"><h4 style={{color:"#ffffff"}}>SELECT</h4></Link>
                    </div>
                </div>
                {/* -------Platinum-------------------------------*/}
                <div className="pricing-box pricing-box-height">
                    <h2 style={{textAlign:"center"}}>Platinum</h2>
                    <p className="price">
                        <sup>$</sup>80<sub>/Week</sub>
                    </p>
                    <ul className="features-list">
                        <li className="list-div">
                            <CheckIcon/> &nbsp;
                            <div><strong>4</strong> bank/credit card account
                            <br/> (In total, Upto 125 transactions/month) </div>
                        </li>
                        <li className="list-div">
                            <CheckIcon/> &nbsp; <div>Bank/Credit Card Reconciliation <br/>
                            <strong>Weekly</strong> </div>
                        </li>
                        <li className="list-div"> <CheckIcon/> &nbsp; Match Invoices to Customer Payments</li>
                        <li className="list-div"> <CheckIcon/> &nbsp; Monthly Aged Receivables Report</li>
                        <li className="list-div">
                            <CheckIcon/> &nbsp;
                            <div>
                                Enter Bills
                                <br/>
                                <p style={{fontStyle: "Italic"}}>
                                    This includes sending remittance advises, processing of bank
                                    file for bulk payments
                                </p>
                                </div>
                        </li>
                        <li className="list-div"> <CheckIcon/> &nbsp; Monthly Aged Payables Report</li>
                        <li className="list-div"> <CheckIcon/> &nbsp; Month End Journal Entries</li>
                        <li className="list-div">
                            <CheckIcon/> &nbsp;  <div>Profit & Loss Statement Review <br/>
                            <strong>Monthly</strong></div>
                        </li>
                        <li className="list-div">
                            <CheckIcon/> &nbsp;
                            <div> Review and Balance Sheet Reconciliation <br/>
                                <strong>Monthly</strong></div>
                        </li>
                        {/*<li className="list-div"> <CheckIcon/> &nbsp;  BAS Preparation and Lodgement</li>*/}
                        <li className="list-div">  <CheckIcon/> &nbsp; Annual Review of Financial Statements</li>
                    </ul>
                    <div className="btn-primary">
                        <Link to="/contact"><h4 style={{color:"#ffffff"}}>SELECT</h4></Link>
                    </div>
                </div>
            </div>

            <div className="pricing-box-container">
                <div className="pricing-box text-center">
                    <h2>Payroll Management</h2>
                    <ul className="features-list">
                        <li></li>
                        <li className="list-div"><CheckIcon/> &nbsp;
                            <div>Superannuation Quarterly Reporting</div>
                        </li>
                        <li className="list-div"><CheckIcon/> &nbsp;
                            <div>Annual Payment Summaries</div>
                        </li>
                        <li className="list-div"><CheckIcon/> &nbsp;
                            <div>Tax File declaration Completion</div>
                        </li>
                        <li className="list-div"><CheckIcon/> &nbsp;
                            <div>Employee Leave Calculation and Payment Processing</div>
                        </li>
                        <li className="list-div"><CheckIcon/> &nbsp;
                            <div>Reconciliation of PAYG Withholding Account</div>
                        </li>
                        <li className="list-div"><CheckIcon/> &nbsp;
                            <div>Pay run Management and STP Reporting</div>
                        </li>
                        <li></li>
                    </ul>
                </div>

                <div className="pricing-box ">
                    <h4>Weekly Pay Run</h4>
                    <ul className="features-list">
                        <li></li>
                        <li>
                            1 - 3 Employees <br/> <strong> $35 per employee per pay run </strong>
                        </li>
                        <li>
                            {" "}
                            4 - 8 Employees <br/><strong> $50 per employee per pay run </strong>
                        </li>
                        <li>
                            {" "}
                            More than 8 Employees <br/><strong> $65 employee per pay run </strong>
                        </li>
                        <li></li>
                    </ul>
                    <div className="btn-primary">
                        <Link to="/contact"><h4 style={{color:"#ffffff"}}>SELECT</h4></Link>
                    </div>
                </div>

                <div className="pricing-box">
                    <h4>Fortnightly Pay Run</h4>
                    <ul className="features-list">
                        <li></li>
                        <li>
                            1 - 10 Employees <br/> <strong> $60 per employee per pay run </strong>
                        </li>
                        <li>
                            {" "}
                            More than 10 Employees <br/> <strong> POA </strong>
                        </li>
                        <li></li>
                    </ul>
                    <div className="btn-primary">
                        <Link to="/contact"><h4 style={{color:"#ffffff"}}>SELECT</h4></Link>
                    </div>
                </div>

            </div>

            <div className="service-container">
                <div className="service">
                    <h2>One Time Zero Setup Fee</h2>
                    <ul className="features-list">
                        <li></li>
                        <li>
                            For 3 Employees : <strong>$295</strong>
                        </li>
                        <li>
                            {" "}
                            More than 3 Employees : <strong>$495</strong>
                        </li>
                        <li></li>
                    </ul>
                    <div className="btn-primary">
                        <Link to="/contact"><h4 style={{color:"#ffffff"}}>SELECT</h4></Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Packages;
